

















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class IotInputNumber extends Vue {
  @Prop() value: any
  @Prop({ type: Number, default: -Infinity }) min: number
  @Prop({ type: Number, default: Infinity }) max: number
  @Prop({ type: Boolean, default: false }) disable: boolean
  @Prop({ type: Boolean, default: false }) placeholder: boolean
  @Prop({ type: Number, default: 1 }) step: number

  numValue: any = 0

  get isEditMode() {
    return this.$store.state.iot.isEditMode
  }

  change() {
    let val = this.numValue
    if (val.length > 0) {
      val = Number(val)
    }

    this.$emit('input', val)
  }
  mounted() {
    this.numValue = this.value
  }
}
