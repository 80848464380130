



































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
type TType = 'all' | 'download' | 'upload'

@Component
export default class IotDownUpload extends Vue {
  @Prop({ type: String }) placeholder: string
  @Prop({ type: String, default: '' }) value: string
  @Prop({ type: String, default: 'all' }) type: TType
  @Prop({ type: String }) suffix: string
  @Prop({ type: Boolean }) readonly: number

  @Ref() readonly hiddenInput: any

  get isEditMode() {
    return this.$store.state.iot.isEditMode
  }

  get downloadVisible() {
    return this.type === 'all' || this.type === 'download'
  }
  get uploadVisible() {
    return this.type === 'all' || this.type === 'upload'
  }

  get downloadDisabled() {
    return this.value === ''
  }

  change() {
    this.$emit('input', this.inputValue)
  }
  download() {
    const xml = window.atob(this.value)
    const blob = new Blob([xml], { type: 'application/xml' })
    const url = window.URL.createObjectURL(blob)
    const name = localStorage.getItem('device-physicalId')!
    const filename = `${name}.${this.suffix}`
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
  }

  upload() {
    this.hiddenInput.click()
  }
  fileName = ''
  handleFile(e: any) {
    const target = e.target || e.srcElement
    const file = target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener(
        'load',
        () => {
          // @ts-ignore
          const base64Code = reader.result.replace(/^data:application\/octet-stream;base64,/, '')
          this.$emit('input', base64Code)
        },
        false
      )
      this.fileName = file.name
    }
    this.hiddenInput.value = null
  }
  removeFile() {
    this.fileName = ''
    this.$emit('input', '')
  }
}
