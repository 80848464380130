






import { Vue, Component, Prop } from 'vue-property-decorator'
@Component
export default class IotButton extends Vue {
  @Prop({ type: String, default: 'primary' }) type: string
  @Prop({ type: String }) text: string
  @Prop({ type: String }) command: string
  @Prop({ type: Boolean }) confirm: boolean
  @Prop({ type: Object }) confirmOption: any
  handleClick() {
    if (this.confirm) {
      this.$confirm(this?.confirmOption?.message, this?.confirmOption?.title, {
        confirmButtonText: this?.confirmOption?.okText ?? '确定',
        cancelButtonText: this?.confirmOption?.cancelText ?? '取消',
        type: this?.confirmOption?.type ?? 'warning'
      })
    }
  }
}
