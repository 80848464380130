



















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class IotTimePicker extends Vue {
  @Prop({ type: String, default: '请选择时间' }) placeholder: string
  @Prop() value: { hour: number; minute: number }
  @Prop({ type: Boolean, default: false }) disable: boolean

  timeValue = ''

  get isEditMode() {
    return this.$store.state.iot.isEditMode
  }

  get formatValue() {
    if (this.value) {
      let minute = String(this.value.minute)
      if (minute.length < 2) {
        minute = minute.padStart(2, '0')
      }
      let hour = String(this.value.hour)
      if (hour.length < 2) {
        hour = hour.padStart(2, '0')
      }
      return `${hour}:${minute}`
    }
    return ''
  }

  change() {
    if (this.timeValue) {
      const [hour, minute] = this.timeValue.split(':')

      if (minute.length < 2) {
        minute.padStart(2, '0')
      }
      this.$emit('input', {
        hour: Number(hour),
        minute: Number(minute)
      })
    } else {
      this.$emit('input', '')
    }
  }
  mounted() {
    this.timeValue = this.formatValue
  }
}
