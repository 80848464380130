
















import { Vue, Component, Prop } from 'vue-property-decorator'

type TType = 'text' | 'textarea'

@Component
export default class IotInput extends Vue {
  @Prop({ type: String }) type: TType
  @Prop({ type: String }) placeholder: string
  @Prop({ type: [String, Object], default: '' }) value: string | object
  @Prop({ type: Number }) minRows: number
  @Prop({ type: Number }) maxRows: number
  @Prop({ type: Boolean }) readonly: number

  get autoSize() {
    const min = this.minRows ?? 1
    const max = this.maxRows ?? 3

    return {
      minRows: min,
      maxRows: max
    }
  }

  get isEditMode() {
    return this.$store.state.iot.isEditMode
  }

  inputValue = ''
  originalValueType: any

  change() {
    this.$emit('input', this.inputValue)
  }
  mounted() {
    this.originalValueType = typeof this.value
    if (this.originalValueType === 'object' && this.value !== null) {
      this.inputValue = JSON.stringify(this.value)
    } else {
      this.inputValue = this.value as string
    }
  }
}
