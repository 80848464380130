
















import { Vue, Component, Prop } from 'vue-property-decorator'
@Component
export default class IotSelect extends Vue {
  @Prop({ type: Array }) options: { label?: string; value: string }[]
  @Prop({ default: '' }) value: string

  selectValue = ''

  get isEditMode() {
    return this.$store.state.iot.isEditMode
  }

  get previewValue() {
    let val = '-'
    this.options.forEach(option => {
      if (!option.label) {
        val = this.value
      } else if (option.value === this.value) {
        val = option.label
      }
    })
    return val
  }

  change() {
    this.$emit('input', this.selectValue)
  }
  mounted() {
    this.selectValue = this.value
  }
}
