




































































import { Vue, Component, Prop } from 'vue-property-decorator'
import formCreate from '@form-create/element-ui'
import IotButton from '@/components/iot/IotButton/index.vue'
import IotSwitch from '@/components/iot/IotSwitch/index.vue'
import IotInput from '@/components/iot/IotInput/index.vue'
import IotInputNumber from '@/components/iot/IotInputNumber/index.vue'
import IotSelect from '@/components/iot/IotSelect/index.vue'
import IotTimePicker from '@/components/iot/IotTimePicker/index.vue'
import IotDownUpload from '@/components/iot/IotDownUpload/index.vue'
import _ from 'lodash'
import { removeObjectEmptyProperty } from '@/utils/helpers'

formCreate.component('iotSwitch', IotSwitch)
formCreate.component('iotInput', IotInput)
formCreate.component('iotInputNumber', IotInputNumber)
formCreate.component('iotSelect', IotSelect)
formCreate.component('iotButton', IotButton)
formCreate.component('iotTimePicker', IotTimePicker)
formCreate.component('iotDownUpload', IotDownUpload)

@Component({ components: {} })
export default class ComponentIndex extends Vue {
  @Prop({ default: false }) isDeviceInfo: boolean
  get commonConfigOption() {
    return {
      submitBtn: false,
      form: {
        labelWidth: '140px',
        labelPosition: 'left'
      }
    }
  }
  get appConfigOption() {
    return {
      submitBtn: false,
      form: {
        labelWidth: '140px',
        labelPosition: 'left'
      }
    }
  }

  getTime(time: Date) {
    return time ? this.$moment(time).format('YYYY-MM-DD HH:mm:ss') : '-'
  }
  get isEditMode() {
    return this.$store.state.iot.isEditMode
  }
  commonConfigFApi: any = {}
  commonConfigValue = {}
  commonConfigApi = {}
  commonConfigRule: any = []

  appConfigRule: any = []
  appConfigValue = {}
  appConfigApi = {}
  appConfigFApi: any = {}

  originCommonConfigRule = {}
  originAppConfigRule = {}

  commandOption = {
    submitBtn: false
  }
  commandFApi: any = {}
  commandValue = {}
  commandApi = {}

  commonTemp = {}
  appTemp = {}
  commandTemp = {}
  attributes: any = {}
  scene = ''
  loading = false
  submitLoading = false
  deviceType = ''
  deviceId = ''
  physicalId = ''

  infos: AnyObj = {}

  async mounted() {
    this.configApi = this.configFApi
    this.commandApi = this.commandFApi
    this.loading = true
    this.scene = this.$store.state.scene
    this.deviceType = localStorage.getItem('device-type')!
    this.deviceId = localStorage.getItem('device-id')!
    this.physicalId = localStorage.getItem('device-physicalId')!

    await this.fetchTemplate()
    await this.fetchDeviceInfo()
    this.loading = false
  }

  async fetchDeviceInfo() {
    this.infos = {}
    const { data } = await this.$api.base.getDevicesSearch({ physicalId: this.physicalId })
    if (data.code === 0) {
      this.infos = data.data && data.data.length > 0 ? data.data[0] : {}
    }
  }

  async fetchTemplate() {
    const { data } = await this.$api.devices.getTemplate(this.deviceType)
    if (data.code === 0) {
      const commonTemp = _.pickBy(data.data.common, component => component.display)
      const appSceneTemp = _.pickBy(
        data.data.apps ? data.data.apps[this.scene] : {},
        component => component.display
      )
      const deviceCommonTemp = _.pickBy(
        data.data.deviceCommon ? data.data.deviceCommon : {},
        component => component.display
      )

      const appTemp = {
        ...appSceneTemp,
        ...deviceCommonTemp
      }

      this.validTemplate({
        ...commonTemp,
        ...appTemp
      })

      this.commonTemp = commonTemp
      this.appTemp = appTemp

      const mergeTemp: any = {
        ...this.commonTemp,
        ...this.appTemp
      }

      const attributeQuery: any = {
        shared: [],
        client: []
      }

      Object.keys(mergeTemp).forEach((tempKey: string) => {
        if (mergeTemp[tempKey].scope === 'shared') {
          attributeQuery.shared.push(tempKey)
        } else if (mergeTemp[tempKey].scope === 'client') {
          attributeQuery.client.push(tempKey)
        }
      })

      await this.fetchAttribute(attributeQuery)
      this.configRule()
    }
  }

  configRule() {
    this.commonConfigRule = this.handleTemplate(this.commonTemp)
    this.appConfigRule = this.handleTemplate(this.appTemp)
    this.originAppConfigRule = _.cloneDeep(this.appConfigRule)
    this.originCommonConfigRule = _.cloneDeep(this.commonConfigRule)
  }

  validTemplate(templates: any) {
    const validFunc = (temp: any, isChild = false) => {
      const childMustProperty = ['type', 'field', 'title']
      const mustProperty = ['type', 'field', 'title', 'scope']
      const mustHasPropsType = ['object', 'input', 'select', 'downUpload']

      if (isChild) {
        childMustProperty.forEach(property => {
          if (!(property in temp)) {
            this.showTemplateError(property, temp)
          }
          if (mustHasPropsType.includes(temp.type)) {
            if (!('props' in temp)) {
              this.showTemplateError('props', temp)
            }
          }
        })
      } else {
        mustProperty.forEach(property => {
          if (!(property in temp)) {
            this.showTemplateError(property, temp)
          }
          if (mustHasPropsType.includes(temp.type)) {
            if (!('props' in temp)) {
              this.showTemplateError('props', temp)
            }
          }
        })
      }
    }

    Object.values(templates).forEach((temp: any) => {
      validFunc(temp)

      if (temp.type === 'object') {
        temp.props.rule.forEach((childTemp: any) => {
          validFunc(childTemp, true)
        })
      }
    })
  }

  async fetchAttribute(query: any) {
    if (query.shared.length > 0) {
      const sharedData: any = await this.$api.devices.getAttributes(
        this.deviceId,
        query.shared,
        'shared'
      )
      if (sharedData.data.code === 0) {
        const { data } = sharedData.data
        this.attributes.shared = {
          ...data
        }
      }
    }
    if (query.client.length > 0) {
      const clientData: any = await this.$api.devices.getAttributes(
        this.deviceId,
        query.client,
        'client'
      )

      if (clientData.data.code === 0) {
        const { data } = clientData.data
        this.attributes.client = {
          ...data
        }
      }
    }
  }

  validFunc(rule: any, value: any, cb: any) {
    if (typeof value === 'number') {
      const { min, max } = rule
      if (_.isNumber(min) && _.isNumber(max)) {
        if (value < min || value > max) {
          cb(`请输入${min}-${max}之间的数字`)
        } else cb()
      } else if (_.isNumber(min) && value < min) {
        cb(`请输入大于${min}的数字`)
      } else if (_.isNumber(max) && value > max) {
        cb(`请输入小于${max}的数字`)
      } else {
        cb()
      }
    } else {
      cb()
    }
  }

  handleTemplate(template: any) {
    // 当客户端属性和共享属性有一样的field时，保存在form-create中的value再拿出来就会变成一个值，所以在field后面拼接上scope，保证每一个value都是唯一的
    return Object.values(template).map((template: any) => {
      if (template.type !== 'object') {
        let val = ''
        if (_.has(this.attributes[template.scope], template.field)) {
          val = this.attributes[template.scope][template.field]
        }
        if (template.type === 'inputNumber') {
          const min = template.props.min ?? null
          const max = template.props.max ?? null

          const valid = [
            {
              type: 'number',
              min,
              max,
              required: false,
              trigger: 'blur',
              validator: this.validFunc
            }
          ]
          return {
            ...template,
            type: this.handleType(template.type),
            value: val,
            validate: valid,
            field: `${template.field}^${template.scope}`
          }
        } else {
          return {
            ...template,
            type: this.handleType(template.type),
            value: val,
            field: `${template.field}^${template.scope}`
          }
        }
      } else {
        const res = {
          ...template,
          props: {
            rule: template.props.rule.map((rule: any) => {
              let val = ''
              if (_.has(this.attributes[template.scope], `${template.field}.${rule.field}`))
                val = this.attributes[template.scope][template.field][rule.field]
              if (rule.type === 'inputNumber') {
                const min = rule.props.min ?? null
                const max = rule.props.max ?? null

                const childValid = [
                  {
                    type: 'number',
                    min,
                    max,
                    required: false,
                    trigger: 'blur',
                    validator: this.validFunc
                  }
                ]
                return {
                  ...rule,
                  type: this.handleType(rule.type),
                  value: val,
                  validate: childValid
                }
              } else
                return {
                  ...rule,
                  type: this.handleType(rule.type),
                  value: val
                }
            })
          }
        }
        return { ...res, field: `${template.field}^${template.scope}` }
      }
    })
  }

  showTemplateError(property: any, template: any) {
    this.$message({
      message: `模板配置错误，请修改模版`,
      type: 'error'
    })
    this.loading = false
    throw new Error(`模板配置错误: ${JSON.stringify(template)}缺少属性${property}`)
  }

  handleType(type: string) {
    if (type !== 'object') {
      return 'iot' + type.slice(0, 1).toUpperCase() + type.slice(1)
    }
    return type
  }

  changeMode() {
    this.$store.commit('iot/changeMode', !this.isEditMode)
  }
  beforeDestroy() {
    this.$store.commit('iot/changeMode', false)
  }

  async submit() {
    await this.commonConfigFApi.validate(async (commonValid: boolean) => {
      if (commonValid) {
        await this.appConfigFApi.validate(async (appValid: boolean) => {
          if (appValid) {
            const paramData: any = {
              ...this.commonConfigValue,
              ...this.appConfigValue
            }
            // 筛选出shared属性，并且key去除^后面的scope
            // 普通类型的字段一直传，嵌套类型的属性为空的就不传
            const formatParamData: any = {}
            Object.keys(paramData).forEach((key: string) => {
              const [newKey, scope] = key.split('^')
              if (scope === 'shared') {
                if (typeof paramData[key] === 'object') {
                  formatParamData[newKey] = removeObjectEmptyProperty(paramData[key])
                } else {
                  formatParamData[newKey] = paramData[key]
                }
              }
            })

            this.submitLoading = true
            const toJsonList = [...this.appConfigRule, ...this.commonConfigRule]
            toJsonList.forEach(e => {
              if (e.props.emit === 'json') {
                if (formatParamData[e.field.split('^')[0]] === '') {
                  formatParamData[e.field.split('^')[0]] = {}
                } else {
                  if (typeof formatParamData[e.field.split('^')[0]] === 'string') {
                    formatParamData[e.field.split('^')[0]] = JSON.parse(
                      formatParamData[e.field.split('^')[0]]
                    )
                  }
                }
              }
            })
            const data = await this.$api.devices.putAttributes(this.deviceId, formatParamData)
            if (data.status === 200) {
              this.$message({
                message: '保存成功！',
                type: 'success'
              })
            }
            this.$store.commit('iot/changeMode', false)
            this.loading = true
            await this.fetchTemplate()

            this.loading = false

            this.submitLoading = false
          }
        })
      }
    })
  }

  resetFormValue() {
    this.appConfigRule = _.cloneDeep(this.originAppConfigRule)
    this.commonConfigRule = _.cloneDeep(this.originCommonConfigRule)
  }

  cancel() {
    this.resetFormValue()
    this.$store.commit('iot/changeMode', false)
  }
}
