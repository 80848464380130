























import { Vue, Component, Prop } from 'vue-property-decorator'
@Component
export default class IotSwitch extends Vue {
  @Prop({ default: true }) activeValue: any
  @Prop({ default: false }) inactiveValue: any
  @Prop({ default: false }) value: any

  switchValue = false

  get isEditMode() {
    return this.$store.state.iot.isEditMode
  }

  change() {
    this.$emit('input', this.switchValue)
  }
  mounted() {
    this.switchValue = this.value
  }
}
